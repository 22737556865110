
import ApiService from "@/common/api.service"

import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_ROADMAP_CONTENT_SEARCH = "roadmapContent"
export const ADD_ROADMAP_CONTENT = "addRoadmapContent"
export const DETAIL_ROADMAP_CONTENT = "detailRoadmapContent"
export const UPDATE_ROADMAP_CONTENT = "updateRoadmapContent"
export const DELETE_ROADMAP_CONTENT = "deleteRoadmapContent"
export const REMOVE_LEARNING_PATH_UUID = "remove-learning-path"

// mutation types
export const SET_LIST_ROADMAP_CONTENT = "setListRoadmapContents"
export const SET_DETAIL_ROADMAP_CONTENT = "setDetailRoadmapContents"
export const SET_ERROR = "setError"


export const state = () => ({
    errors: null,
    roadmapContents: [],
    roadmapContent: {},
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    roadmapContents: state => state.roadmapContents,
    roadmapContent: state => state.roadmapContent,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [SET_LIST_ROADMAP_CONTENT](state, roadmapContents) {
        state.roadmapContents = roadmapContents
    },
    [SET_DETAIL_ROADMAP_CONTENT](state, roadmapContent) {
        state.roadmapContent = roadmapContent
    },
}

const actions: ActionTree<RootState, RootState> = {
    [DETAIL_ROADMAP_CONTENT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.ROADMAP_CONTENT, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_DETAIL_ROADMAP_CONTENT, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [LIST_ROADMAP_CONTENT_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.ROADMAP_CONTENT, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_LIST_ROADMAP_CONTENT, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [ADD_ROADMAP_CONTENT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.ROADMAP_CONTENT, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [UPDATE_ROADMAP_CONTENT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.ROADMAP_CONTENT}/${credentials.uuid}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [DELETE_ROADMAP_CONTENT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${API.ROADMAP_CONTENT}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [REMOVE_LEARNING_PATH_UUID](context, item) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${REMOVE_LEARNING_PATH_UUID}`, { uuid: item.uuid })
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
}
const roadmapContentModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default roadmapContentModule