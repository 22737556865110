
import ApiService from "@/common/api.service"

import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_LEARNING_PATHS_SEARCH = "learningPaths"
export const ADD_LEARNING_PATH = "addLearningPath"
export const DETAIL_LEARNING_PATH = "detailLearningPath"
export const UPDATE_LEARNING_PATH = "updateLearningPath"
export const DELETE_LEARNING_PATH = "deleteLearningPath"

// mutation types
export const SET_LIST_LEARNING_PATHS = "setListLearningPaths"
export const SET_DETAIL_LEARNING_PATH = "setDetailLearningPaths"
export const SET_ERROR = "setError"


export const state = () => ({
    errors: null,
    learningPaths: [],
    learningPath: {},
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    learningPaths: state => state.learningPaths,
    learningPath: state => state.learningPath,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [SET_LIST_LEARNING_PATHS](state, learningPaths) {
        state.learningPaths = learningPaths
    },
    [SET_DETAIL_LEARNING_PATH](state, learningPath) {
        state.learningPath = learningPath
    },
}

const actions: ActionTree<RootState, RootState> = {
    [DETAIL_LEARNING_PATH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.LEARNING_PATHS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_DETAIL_LEARNING_PATH, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [LIST_LEARNING_PATHS_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.LEARNING_PATHS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_LIST_LEARNING_PATHS, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    [ADD_LEARNING_PATH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.LEARNING_PATHS, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [UPDATE_LEARNING_PATH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.LEARNING_PATHS}/${credentials.uuid}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [DELETE_LEARNING_PATH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${API.LEARNING_PATHS}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    }
}
const learningPathModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default learningPathModule