export default {
  validate: {
    email: "Email chưa đúng định dạng, vui lòng nhập lại!",
    min: "Vui lòng nhập tối thiểu {number} ký tự!",
    max: "Vui lòng nhập tối đa {number} ký tự!",
    max2: "Số điện thoại phải {min}<=SĐT<={max}",
    required: "Vui lòng điền {filed}!",
    numeric: "Vui lòng nhập số!",
    number: "{filed} không được chứa ký tự đặc biệt. Vui lòng chỉ nhập số!",
    file_or_link: "Bạn chưa upload file hoặc gắn link!",
    date: "Ngày và giờ hiển thị không được chọn ngày quá khứ!",
    date_compare: "Ngày kết thúc phải sau ngày bắt đầu!",
    required_class_room: "Vui lòng chọn lớp để copy!",
    confirmed: "Xác nhận mật khẩu không khớp!",
    size: "{filed} tải lên có dung lượng <= {number}",
    phone_is_valid: "Số điện thoại không hợp lệ",
    unique: "{field} đã tồn tại",
    image: "Upload ảnh không đúng định dạng",
    reason_trim: "Vui lòng không nhập khoảng trắng 2 đầu dòng.",
    mark: "Điểm số không hợp lệ",
    type_file: "File tải lên phải có định dạng file excel",
    max_address: "Địa chỉ không vượt quá 255 ký tự",
    password_old_not_same: "Mật khẩu mới không được trùng mật khẩu cũ",
    invalid_format: "{field} không hợp lệ!",
    name: "Tên",
    name_road: "Tên lộ trình",
    send_time_road: "Thời gian gửi",
    exams_road: "Danh sách đề thi",
  },
  category: {
    name: "Tên chuyên mục",
    slug: "Slug",
  },
  admin: {
    email: "Email ",
    password: "Mật khẩu ",
    user_name: "Tên tài khoản ",
    roles: "Vai trò ",
    company_id: "Đơn vị ",
  },
  company: {
    name: "Tên đơn vị ",
  },

  hello: "XIn Chao",
  action: "THAO TÁC",
  menu: {
    exam: {
      title: "Quản lý đề thi Admin",
      child: {
        category: "Chuyên mục",
        exam: "Đề thi",
        score_sheet: "Bảng điểm",
        exam_ecommerce: "Đề thi bán"
      },
    },
    exam_user: {
      title: "Quản lý đề thi User",
      child: {
        exam: "Đề thi",
        exam_ecommerce: "Đề thi bán",
      },
    },
    learning_path: {
      title: "Quản lý Lộ trình",
      child: {
        list: "Danh sách Lộ trình",
      },
      roadmap_content: {
        title: "Danh sách Bài học"
      }
    },
    group: {
      title: "Quản lý cuộc thi",
      child: {
        list: "Danh sách cuộc thi",
      },
    },
    order: {
      title: "Quản lý Đơn hàng",
      child: {
        list: "Danh sách Đơn hàng",
      },
    },
    statistics: {
      title: "Thống kê lượt thi",
      child: {
        list: "Danh sách lượt thi",
        user: "Người dùng",
        exam: "Đề thi",
        question: "Câu hỏi"
      },
    },
    road: {
      title: "Quản lý lộ trình",
      child: {
        list: "Danh sách lộ trình",
        road_sent: "Danh sách đã gửi"
      },
    },
    keyword: {
      title: "Quản lý Từ khóa",
      child: {
        search_query: "Danh sách Tìm kiếm",
        keyword: "Danh sách Từ khóa"
      },
    },
    blog: {
      title: "Quản lý Blog",
      child: {
        category_newese: 'Danh sách chuyên mục Blog',
        newses: 'Danh sách bài viết Blog',
      },
      name: 'Tên bài viết'
    },
    ads: {
      title: "Quản lý Quảng cáo",
      child: {
        display_ads: 'Vị trí hiển thị',
        tab_ads: 'Trang hiển thị',
        content_ads: 'Nội dung quảng cáo',
        ads: 'Thiết lập quảng cáo'
      }
    },
    footer: {
      title: 'Cấu hình Web',
      child: {
        adviser: "Quản lý Cố vấn",
        media_press: "Quản lý Báo chí",
        menu_footer: "Quản lý Menu footer",
      }
    },
    schedule: "Quản lý lịch thi",
    company: "Quản lý đơn vị",
    admin: "Quản lý admin",
    api_key: "Quản lý api key",
    user: "Quản lý người dùng",
    roles: "Quản lý vai trò",
    comment: "Quản lý Bình luận"
  },
  logout: "Đăng xuất",
  login: {
    description:
      "Nền tảng Thi trắc nghiệm, Trộn và Tạo đề thi miễn phí nhiều người sử dụng nhất",
    title: "Đăng nhập",
    email: "Nhập email",
    password: "Nhập mật khẩu",
  },
  not_result: 'Không có kết quả tìm kiếm',
  type_road: {
    daily: 'Hằng ngày',
    days_of_week: 'Ngày trong tuần',
    specific_dates: 'Chọn ngày'
  },
  days_of_week: {
    monday: 'Thứ 2',
    tuesday: 'Thứ 3',
    wednesday: 'Thứ 4',
    thursday: 'Thứ 5',
    friday: 'Thứ 6',
    saturday: 'Thứ 7',
    sunday: 'Chủ Nhật',
  }
}
